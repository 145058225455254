import React from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'

import Layout from '../components/layout'
import SEO from '../components/seo'
import {
	Container,
	Title,
	Content,
	Button,
  Grid,
  Gallery,
  Image
} from '../components/styled'
import Quote from '../components/Quote'
import Contact from '../components/Contact'

export default ({ data }) => {
  //console.log(data)
  return (
  <Layout>
    <SEO
      title="Pagespeed-Optimierung - Jede Sekunde = mehr Umsatz - jetzt starten!"
      description="► Audit in den Bereichen User Experience (UX) und Suchmaschinenoptimierung (SEO): ✓ UX-Audit ✓ SEO-Audit ✓ Standort Köln / Bonn ► UX/SEO-Audit anfragen"
    />
  	<Container>
  		<Container.Inner>
  			<Content>Pagespeed-Optimierung</Content>
        <Title as="h1">Jede Sekunde bringt Ihnen mehr Umsatz</Title>
        <Content big>
          <p>Durch Pagespeed-Optimierung machen Sie Ihre Webseite oder Landingpage um wertvolle Sekunden schneller und erhöhen damit die Wahrscheinlichkeit, einen potenziellen Kunden zu gewinnen, bevor er abspringt.</p>
          <Button as={Link} primary="true" to="/kontakt/">Pagespeed-Optimierung anfragen</Button>
        </Content>
		  </Container.Inner>
	  </Container>
    <Container>
      <Container.Inner>
        <Grid className="nb5 flex-wrap-l items-center-l justify-center-l">
          <Grid.Col className="mb5 w-50-l order-1-l">
            <Title small>Pagespeed-Audit</Title>
            <Content>
              <p>Im Pagespeed-Audit wird ermittelt, wie schnell Ihre Webseite ist. Und an welchen Stellschrauben Sie drehen müssen, damit Ihre Webseite noch schneller wird. Die empfohlenen Maßnahmen sind im Audit nach Aufwand und Nutzen gegliedert, und können an Ihre Programmierer oder Web-Agentur zur Umsetzung weitergegeben werden.</p>
            </Content>
          </Grid.Col>
          <Grid.Col className="mb5 w-50-l order-2-l">
            <Gallery>
              <Gallery.Item><Image as={Img} fixed={data.webpagetestLogo.childImageSharp.fixed} /></Gallery.Item>
              <Gallery.Item><Image as={Img} fixed={data.googlePagespeedInsightsLogo.childImageSharp.fixed} /></Gallery.Item>
              <Gallery.Item><Image as={Img} fixed={data.googleLighthouseLogo.childImageSharp.fixed} /></Gallery.Item>
              <Gallery.Item><Image as={Img} fixed={data.screamingFrogLogo.childImageSharp.fixed} /></Gallery.Item>
              <Gallery.Item><Image as={Img} fixed={data.html5Logo.childImageSharp.fixed} /></Gallery.Item>
            </Gallery>
          </Grid.Col>
          <Grid.Col className="mb5 w-50-l order-3-l">
            <Title small>Pagespeed-Optimierung</Title>
            <Content>
              <p>Haben Sie eine statische oder WordPress-basierte Webseite oder Landingpage, kann ich auf Wunsch die Pagespeed-Optimierung selbst durchführen. Je nach Ausgangslange, insb. bei „gekauften“ Themes, kann nur eine Teil-Optimierung erfolgen, die Ihnen aber schon mal einen ersten Geschwindigkeitsschub bringt. So profitieren Sie direkt von einer schnelleren Webseite..</p>
            </Content>
          </Grid.Col>
        </Grid>
      </Container.Inner>
    </Container>
    <Container>
      <Container.Inner>
        <Quote {...data.allTestimonialsYaml.edges.find(el => el.node.name === 'pluspulso').node} />
      </Container.Inner>
    </Container>
    <Contact>
      <Title center>Ihre Kunden wollen nicht lange warten, beschleunigen Sie jetzt Ihre Webseite</Title>
      <Content big center>
        <p>Mit einer professionellen Pagespeed-Optimierung erhöhen Sie die User Experience, die Chance auf ein gutes Ranking in Suchmaschinen und Ihren direkten Online-Umsatz.</p>
        <Button as={Link} primary="true" to="/kontakt/">Pagespeed-Optimierung anfragen</Button>
      </Content>
    </Contact>
  </Layout>
)}

export const query = graphql`
  query {
    allTestimonialsYaml(filter: {name: {in: [ "pluspulso"]}}) {
      edges {
        node {
          name
          children: content
          source
          image {
            ...rectangleImage
          }
          logo {
            ...logo
          }
        }
      }
    }
    webpagetestLogo: file(relativePath: { eq: "webpagetest.png" }) {
      ...logoFixedBig
    }
    googlePagespeedInsightsLogo: file(relativePath: { eq: "google-pagespeed-insights.png" }) {
      ...logoFixedBig
    }
    googleLighthouseLogo: file(relativePath: { eq: "google-lighthouse.jpg" }) {
      ...logoFixedBig
    }
    screamingFrogLogo: file(relativePath: { eq: "screaming-frog.jpg" }) {
      ...logoFixedBig
    }
    html5Logo: file(relativePath: { eq: "html5.png" }) {
      ...logoFixedBig
    }
  }
`
